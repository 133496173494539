import { Link } from 'components';
import NavColumn from './NavColumn';
import NavArrow from 'assets/navArrow.svg';
import ArrowUpRight from 'assets/arrowUpRight.svg';
import classNames from 'classnames';

const NavItem = ({ navItem, level, activeMenu, setActiveMenu, close, parentMenuName, dividerTop, dividerBottom }) => {
  const _setActiveMenu = (sub) => {
    let newValue = {};
    newValue[`l${level + 1}`] = sub;
    setActiveMenu(newValue);
  };

  const linkClass = classNames(
    'py-3 lg:py-2 font-montserrat text-base not-italic font-normal leading-6.5 cursor-pointer flex items-center justify-between duration-500 [&_svg]:duration-500',
    'text-black [&_svg]:fill-black',
    level === 2 ? 'hover:text-white [&_svg]:hover:fill-white' : 'hover:text-black [&_svg]:hover:fill-black',
    activeMenu && activeMenu[`l${level + 1}`] && navItem.flyout?.length > 0
      ? level === 2
        ? 'lg:text-brand-soft-white lg:[&_svg]:fill-brand-soft-white'
        : 'lg:text-black lg:[&_svg]:fill-black'
      : level === 2
        ? 'lg:text-brand-soft-white lg:[&_svg]:fill-brand-soft-white'
        : 'lg:text-black lg:[&_svg]:fill-black',
  );

  return (
    <>
      {dividerTop && (
        <hr
          className={classNames(level === 2 ? 'lg:text-white/25' : 'lg:text-black/25', 'text-black/25 my-5 lg:my-2')}
          data-testid="nav-divider"
        />
      )}
      <div>
        {navItem.flyout?.length > 0 ? (
          <div
            className={linkClass}
            onClick={() => _setActiveMenu({})}
            data-testid={
              level === 1
                ? 'nav-item-level-1'
                : level === 2
                  ? 'nav-item-level-2'
                  : level === 3
                    ? 'nav-item-level-3'
                    : 'nav-item'
            }
          >
            {navItem.navItemText || navItem.navigationalTitle}
            {navItem.flyout?.length > 0 && <NavArrow role="presentation" />}
          </div>
        ) : (
          <Link
            className={linkClass}
            link={
              navItem.navItemLink ||
              (navItem.ctaLink && { ...navItem.ctaLink, text: navItem.navigationalTitle || navItem.ctaLink.text })
            }
            onClick={close}
            data-testid={
              level === 1
                ? 'nav-item-level-1'
                : level === 2
                  ? 'nav-item-level-2'
                  : level === 3
                    ? 'nav-item-level-3'
                    : 'nav-item'
            }
          >
            {navItem.navItemLink?.text || navItem.navigationalTitle || navItem.ctaLink?.text}
            {navItem.navigationText && <ArrowUpRight role="presentation" />}
          </Link>
        )}

        {(!navItem.flyout || navItem.flyout.length === 0) && !(navItem.navItemLink || navItem.ctaLink) && (
          <div className={linkClass} onClick={() => _setActiveMenu({})}>
            {navItem.navItemText || navItem.navigationalTitle}
          </div>
        )}

        {navItem.navigationText && (
          <div className="not-italic font-normal text-sm leading-6 lg:leading-5 lg:text-xs font-montserrat text-black lg:text-brand-soft-white mt-6">
            {navItem.navigationText}
          </div>
        )}
      </div>

      {navItem.flyout?.length > 0 && (
        <NavColumn
          navItems={navItem.flyout}
          detailedNavItems={navItem.detailedNavItems}
          level={level + 1}
          activeMenu={activeMenu}
          setActiveMenu={(sub) => _setActiveMenu(sub)}
          close={close}
          cta={navItem.flyout && navItem.flyout.length !== 0 ? navItem.navItemLink || navItem.ctaLink : null}
          ctaClass={linkClass}
          parentMenuName={level === 0 ? navItem.navItemText || navItem.navigationalTitle : parentMenuName}
          data-testid={
            level === 1 ? 'flyout-level-1' : level === 2 ? 'flyout-level-2' : level === 3 ? 'flyout-level-3' : undefined
          }
        />
      )}

      {dividerBottom && (
        <hr
          className={classNames(
            level === 2 ? 'lg:text-white/25' : 'lg:text-black/25',
            'text-black/25 mt-8 lg:mt-4 mb-5 lg:mb-2',
          )}
        />
      )}
    </>
  );
};

export default NavItem;
