import React, { useState, useRef } from 'react';
import { FormControl, PopoverMenu } from 'components';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const AvailabilityForm = ({ availabilityData, searchClass, placement, searchBtnText }) => {
  const [resort, setResort] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [
    infants, //setInfants
  ] = useState(0);
  const router = useRouter();
  const { t } = useTranslation('common');

  const datepicker = useRef();
  const guests = useRef();

  useEffect(() => {
    setResort(availabilityData?.selectedResortBookingCode);
  }, [availabilityData?.selectedResortBookingCode]);

  const _setResort = (event) => {
    setResort(event.target.value);
    datepicker.current.open();
  };

  const setDates = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newStartDate && newEndDate) {
      guests.current.open();
    }
  };

  const formValid = !!resort && !!startDate && !!endDate;

  let bookingUrl = {};
  if (availabilityData?.bookingSystemUrl) {
    try {
      bookingUrl = new URL(availabilityData.bookingSystemUrl + resort);

      let languageid = 1;
      if (router.locale === 'ru') {
        languageid = 9;
      } else if (router.locale === 'tr') {
        languageid = 19;
      } else if (router.locale === 'de') {
        languageid = 7;
      }

      bookingUrl.searchParams.set('languageid', languageid);

      if (startDate) {
        bookingUrl.searchParams.set('DateIn', dayjs(startDate)?.format('MM%2FDD%2FYYYY'));
      }

      if (endDate) {
        bookingUrl.searchParams.set('DateOut', dayjs(endDate)?.format('MM%2FDD%2FYYYY'));
      }

      if (adults) {
        bookingUrl.searchParams.set('Adults', adults);
      }

      if (children) {
        bookingUrl.searchParams.set('Children', children);
      }

      if (infants) {
        bookingUrl.searchParams.set('Children2', infants);
      }
    } catch (e) {
      console.error('Invalid URL: ' + availabilityData.bookingSystemUrl);
    }
  }

  return (
    <>
      <div className="form-items-container flex flex-col lg:flex-row gap-2 [&_.main-button]:!h-12">
        <div className="w-full form-item resort">
          <FormControl
            type="select"
            className={classNames(
              'dropdown [&_.main-button]:pt-[11px] [&_.main-button]:pb-[11px] [&_em]:not-italic',
              resort && 'selected',
            )}
            value={resort}
            onChange={_setResort}
            displayEmpty={false}
            placeholder={t('booking.$selectResort')}
            options={availabilityData?.resorts.map((item) => ({
              value: item.bookingCode,
              label: item.resortName,
            }))}
            placement={placement}
            data-testid="resort"
          />
        </div>
        <div className="spacer" />
        <div className="w-full form-item dates" data-testid="date-picker">
          <FormControl
            popoverRef={datepicker}
            type="datepicker"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={(event) => setDates(event.target.value)}
            placeholder={t('booking.$selectDates')}
            minDate={new Date(availabilityData?.nextBookableDate)}
            className={classNames(startDate && endDate && 'selected')}
            placement={placement}
          />
        </div>
        <div className="spacer" />
        <div className="w-full form-item guests input-group mt-0">
          <PopoverMenu
            // title={t('booking.$xAdultsXChildrenXInfants', { adults, children, infants })}
            title={t('booking.$xAdultsXChildren', { adults, children })}
            className={classNames(
              'dropdown [&_.popover-title]:text-brand-soft-white/75 [&_.popover-title]:!text-sm [&_.popover-title]:!leading-6',
              (adults !== 2 || children !== 0 || infants !== 0) && 'selected',
            )}
            placement={placement}
            ref={guests}
          >
            <div className="p-3 w-full">
              <div className="flex items-center justify-between [&_.input-group]:!mt-0">
                <div className="font-montserrat text-sm not-italic font-normal leading-6 py-2.5">
                  {t('booking.$adults')}
                </div>
                <FormControl
                  type="number"
                  value={adults}
                  onChange={(event) => setAdults(event.target.value)}
                  minValue={1}
                  maxValue={availabilityData?.maxAdults}
                  className="input !p-0 !w-24 [&_input]:mx-3"
                />
              </div>
              <div className="flex items-center justify-between [&_.input-group]:!mt-0">
                <div className="py-2.5">
                  <div className="font-montserrat text-sm not-italic font-normal leading-6">
                    {t('booking.$children')}
                  </div>
                  <div className="font-montserrat text-xs not-italic font-normal leading-5 text-black/25">
                    {t('booking.$ages511')}
                  </div>
                </div>
                <FormControl
                  type="number"
                  value={children}
                  onChange={(event) => setChildren(event.target.value)}
                  minValue={0}
                  maxValue={availabilityData?.maxChildren}
                  className="input !p-0 !w-24 [&_input]:mx-3"
                />
              </div>
              {/*<div className="flex items-center justify-between [&_.input-group]:!mt-0">
                <div className="py-2.5">
                  <div className="font-montserrat text-sm not-italic font-normal leading-6">
                    {t('booking.$infants')}
                  </div>
                  <div className="font-montserrat text-xs not-italic font-normal leading-5 text-black/25">
                    {t('booking.$ages02')}
                  </div>
                </div>
                <FormControl
                  type="number"
                  value={infants}
                  onChange={(event) => setInfants(event.target.value)}
                  minValue={0}
                  maxValue={availabilityData?.maxChildren}
                  className="input !p-0 !w-24 [&_input]:mx-3"
                />
              </div>*/}
            </div>
          </PopoverMenu>
        </div>
      </div>
      <div className="search-container">
        <a
          href={bookingUrl.href}
          className={classNames('btn h-12 px-12', searchClass, !formValid && '!opacity-25 pointer-events-none')}
          aria-label={t('general.$opensanewwindow', { ariaLabel: t('general.$search') })}
          target="_blank"
        >
          {searchBtnText || t('general.$search')}
        </a>
      </div>
    </>
  );
};

export default AvailabilityForm;
