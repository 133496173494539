import Arrow from '@/assets/arrow.svg';
import { HeadingTag, Link, MediaLightboxCarousel, ModuleBase, ResponsiveImage } from '@/components';
import CarouselPagination from '@/components/general/CarouselPagination';
import { isColorDark } from '@/utils';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { Fragment, useEffect, useRef, useState } from 'react';
import GridIcon from '../assets/grid.svg';
import SingleIcon from '../assets/single.svg';

const MediaMasonryGridModule = ({ data }) => {
  const { headingTitle, preHeading, description, cta, mediaSection } = data || {};
  const carousel = useRef();
  const [isOpen, setIsOpen] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);
  const [isSingleView, setIsSingleView] = useState(false);
  const dark = isColorDark(data.backgroundColour);
  const { t } = useTranslation('common');
  const images = [];

  mediaSection.map((row) => row.images?.map((image) => images.push(image)));

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
  };

  useEffect(() => {
    if (isSingleView) {
      setIsOpen('0');
    }
  }, [isSingleView]);

  useEffect(() => {
    if (isOpen) {
      setIsSingleView(true);
    } else {
      setIsSingleView(false);
    }
  }, [isOpen]);

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  const handleClick = (image) => {
    const index = images.indexOf(image);

    setIsOpen(String(index));
  };

  return (
    <>
      <MediaLightboxCarousel
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        slides={images}
        backgroundColour={'#000000'}
        imageWidths={{ xs: 332, sm: 332, md: 688, lg: 1016, xl: 1016, xxl: 1016 }}
        imageHeights={{ xs: 163, sm: 163, md: 328, lg: 485, xl: 485, xxl: 485 }}
        imageContainerWidthClassName="w-[332px] md:w-[688px] lg:w-[1016px]"
        imageContainerHeightClassName="h-[163px] md:h-[328px] lg:h-[485px]"
        moduleName="MediaMasonryGridModule"
      />

      <ModuleBase data={data} className="relative">
        <div className="m-auto mx-10 mb-12 max-w-[1224px] gap-2 xl:mx-auto">
          <div className="title-wrapper w-full">
            {preHeading && (
              <p className="color-from-bg font-montserrat text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase mb-4 lg:text-sm lg:font-medium lg:tracking-button-reg">
                {preHeading}
              </p>
            )}
            {headingTitle && (
              <HeadingTag
                data={headingTitle}
                className="color-from-bg font-montserrat text-base not-italic font-semibold leading-5 tracking-headline-m-tablet uppercase md:text-xl md:leading-6 md:tracking-quote-reg-mobile"
              />
            )}
          </div>
          <div className="flex w-full flex-col md:max-w-[332px] lg:max-w-[808px]">
            {description && (
              <div className="grow md:mt-4">
                <div
                  dangerouslySetInnerHTML={{ __html: description }}
                  className="color-from-bg text-sm not-italic font-normal leading-6 lg:leading-6.5 lg:text-base font-montserrat text-black/75"
                ></div>
              </div>
            )}
            {cta && <Link className="mt-4 color-from-bg btn md:w-fit primary" link={cta}></Link>}
          </div>
        </div>

        <div className="hidden max-w-fit mx-auto xl:flex items-center mb-12 gap-6">
          <button onClick={() => setIsSingleView(false)} className="flex w-fit items-center gap-2 color-from-bg">
            <GridIcon className="size-10" />
            <span
              className={classNames(
                ' font-montserrat relative py-2 after:w-full after:content-[""] after:absolute after:h-px after:bottom-0 after:left-0 after:scale-x-0 after:origin-left after:transition-transform after:duration-500',
                !isSingleView ? 'after:scale-x-100' : '',
                dark ? 'after:bg-white' : 'after:bg-black',
              )}
            >
              {t('gallery.$grid')}
            </span>
          </button>
          <button onClick={() => setIsSingleView(true)} className="flex w-fit items-center gap-2 color-from-bg">
            <SingleIcon className="size-10" />
            <span
              className={classNames(
                'font-montserrat relative py-2 after:w-full after:content-[""] after:absolute after:h-px after:bottom-0 after:left-0 after:scale-x-0 after:origin-left after:transition-transform after:duration-500',
                isSingleView ? 'after:scale-x-100' : '',
                dark ? 'after:bg-white' : 'after:bg-black',
              )}
            >
              {t('gallery.$single')}
            </span>
          </button>
        </div>

        {mediaSection && (
          <div className="m-auto max-w-[1304px]">
            <div className="hidden grid-cols-[288px_repeat(2,minmax(0,1fr))_392px] auto-rows-fr gap-6 px-6 md:px-10 xl:grid">
              {mediaSection.map((mediaRow, index) => {
                if (mediaRow.images?.length === 3) {
                  return (
                    <Fragment key={`row-${index}`}>
                      <div
                        className="relative w-[288px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[0])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[0].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[0].title}</div>
                          )}
                          {mediaRow.images[0].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[0].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[0]} widths={{ xl: 432 }} heights={{ xl: 506 }} />
                      </div>
                      <div
                        className="relative col-span-3 row-span-2 w-[912px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[1])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[1].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[1].title}</div>
                          )}
                          {mediaRow.images[1].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[1].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[1]} widths={{ xl: 1368 }} heights={{ xl: 770 }} />
                      </div>
                      <div
                        className="relative w-[288px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[2])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[2].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[2].title}</div>
                          )}
                          {mediaRow.images[2].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[2].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[2]} widths={{ xl: 432 }} heights={{ xl: 506 }} />
                      </div>
                    </Fragment>
                  );
                }

                if (mediaRow.images?.length === 5) {
                  return (
                    <Fragment key={`row-${index}`}>
                      <div
                        className="relative col-span-3 row-span-2 w-[808px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[0])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[0].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[0].title}</div>
                          )}
                          {mediaRow.images[0].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[0].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[0]} widths={{ xl: 1212 }} heights={{ xl: 670 }} />
                      </div>
                      <div
                        className="relative w-[392px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[1])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[1].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[1].title}</div>
                          )}
                          {mediaRow.images[1].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[1].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[1]} widths={{ xl: 588 }} heights={{ xl: 506 }} />
                      </div>
                      <div
                        className="relative row-span-2 w-[392px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[4])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[4].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[4].title}</div>
                          )}
                          {mediaRow.images[4].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[4].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[4]} widths={{ xl: 588 }} heights={{ xl: 1049 }} />
                      </div>
                      <div className="relative w-[288px] cursor-pointer" onClick={() => handleClick(index, 2)}>
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[2].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[2].title}</div>
                          )}
                          {mediaRow.images[2].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[2].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[2]} widths={{ xl: 432 }} heights={{ xl: 506 }} />
                      </div>
                      <div
                        className="relative col-span-2 w-[496px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[3])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[3].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[3].title}</div>
                          )}
                          {mediaRow.images[3].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[3].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[3]} widths={{ xl: 744 }} heights={{ xl: 428 }} />
                      </div>
                    </Fragment>
                  );
                }

                if (mediaRow.images?.length === 7) {
                  return (
                    <Fragment key={`row-${index}`}>
                      <div
                        className="relative col-span-3 row-span-2 w-[808px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[0])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[0].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[0].title}</div>
                          )}
                          {mediaRow.images[0].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[0].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[0]} widths={{ xl: 1212 }} heights={{ xl: 670 }} />
                      </div>
                      <div
                        className="relative w-[392px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[1])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[1].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[1].title}</div>
                          )}
                          {mediaRow.images[1].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[1].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[1]} widths={{ xl: 588 }} heights={{ xl: 506 }} />
                      </div>
                      <div
                        className="relative row-span-2 w-[392px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[4])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[4].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[4].title}</div>
                          )}
                          {mediaRow.images[4].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[4].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[4]} widths={{ xl: 588 }} heights={{ xl: 1049 }} />
                      </div>
                      <div
                        className="relative w-[288px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[2])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[2].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[2].title}</div>
                          )}
                          {mediaRow.images[2].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[2].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[2]} widths={{ xl: 432 }} heights={{ xl: 506 }} />
                      </div>
                      <div
                        className="relative col-span-2 w-[496px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[3])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[3].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[3].title}</div>
                          )}
                          {mediaRow.images[3].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[3].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[3]} widths={{ xl: 744 }} heights={{ xl: 428 }} />
                      </div>
                      <div
                        className="relative col-span-2 w-[600px] cursor-pointer"
                        onClick={() => handleClick(mediaRow.images[5])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[5].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[5].title}</div>
                          )}
                          {mediaRow.images[5].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[5].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[5]} widths={{ xl: 900 }} heights={{ xl: 506 }} />
                      </div>
                      <div
                        className="relative col-span-2 w-[600px] cursor-pointer justify-self-end"
                        onClick={() => handleClick(mediaRow.images[6])}
                      >
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {mediaRow.images[6].title && (
                            <div className="font-inter text-lg font-semibold leading-6">{mediaRow.images[6].title}</div>
                          )}
                          {mediaRow.images[6].description && (
                            <div className="mt-2 font-inter text-base">{mediaRow.images[6].description}</div>
                          )}
                        </div>
                        <ResponsiveImage image={mediaRow.images[6]} widths={{ xl: 900 }} heights={{ xl: 506 }} />
                      </div>
                    </Fragment>
                  );
                }
              })}
            </div>
            <div className="flex xl:hidden">
              <div className="w-full pl-6 md:m-auto md:pl-10">
                <Flicking
                  ref={carousel}
                  align="prev"
                  useResizeObserver={true}
                  horizontal={true}
                  circular={true}
                  circularFallback="bound"
                  className="pb-[74px]"
                  onReady={onReady}
                  onWillChange={willChange}
                >
                  {images.map((image, index) => (
                    <div key={index} className="mx-3 flex w-[270px] flex-col md:w-[569px]">
                      <div className="h-[151px] cursor-pointer md:h-[320px]" onClick={() => setIsOpen(String(index))}>
                        <div className="pointer-events-none absolute flex h-full w-full flex-col items-start justify-end p-6">
                          {image.title && (
                            <div className="font-inter text-xs font-semibold md:text-sm">{image.title}</div>
                          )}
                          {image.description && (
                            <div className="mt-2 hidden font-inter text-xs md:block">{image.description}</div>
                          )}
                        </div>
                        <ResponsiveImage
                          image={image}
                          widths={{ xs: 270, sm: 270, md: 569, lg: 569 }}
                          heights={{ xs: 151, sm: 151, md: 320, lg: 320 }}
                        />
                      </div>
                    </div>
                  ))}
                </Flicking>

                <div className="-mt-8 mr-8 flex justify-between items-center gap-3">
                  <CarouselPagination
                    total={panelCount}
                    dark={dark}
                    current={currentIndex}
                    moduleName="MediaMasonryGrid"
                  />
                  <div className="flex gap-3">
                    <button
                      className={`btn border-none circle bg-brand-green [&_svg]:!stroke-white p-0 w-10 h-10 sm:w-12 sm:h-12 z-10 ${
                        currentIndex === 0
                          ? 'opacity-50 transition-all  duration-1000 pointer-events-none'
                          : 'opacity-100 transition-all duration-1000'
                      }`}
                      onClick={move}
                      aria-label={t('general.$previous')}
                    >
                      <Arrow className="rotate-180" />
                    </button>
                    <button
                      className={`btn border-none circle bg-brand-green [&_svg]:!stroke-white p-0 w-10 h-10 sm:w-12 sm:h-12 z-10 ${
                        currentIndex === images?.length - 1
                          ? 'opacity-50 transition-all duration-1000 pointer-events-none'
                          : 'opacity-100 transition-all duration-1000'
                      }`}
                      onClick={() => move(1)}
                      aria-label={t('general.$next')}
                    >
                      <Arrow />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </ModuleBase>
    </>
  );
};
export default MediaMasonryGridModule;
