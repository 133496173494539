import React, { useState, useRef, useContext, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Link, LanguageSelect, Nav, ResponsiveImage, ResortNav, SmootherContext } from '@/components';
import classNames from 'classnames';
import Menu from 'assets/menu.svg';
import MontgomeriemaxxroyalLogo from 'assets/montgomeriemaxxroyalLogo.svg';
import tailwindConfig from '../tailwind.config.js';

const Header = ({ header, metadata, filledHeader }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [resortMenuOpen, setResortMenuOpen] = useState(false);
  const { t } = useTranslation('common');
  const router = useRouter();
  const mainNavRef = useRef();
  const scrollSmoother = useContext(SmootherContext);

  const addQueryParam = (params) => {
    router.replace(
      {
        query: { ...router.query, ...params },
      },
      undefined,
      { shallow: true },
    );
  };

  useEffect(() => {
    if (router.query.contact) {
      return;
    }

    closeMenus();
  }, [router.query]);

  const openMenu = () => {
    scrollSmoother.scrollTo(0);
    if (
      window.innerWidth < parseInt(tailwindConfig.theme.screens.lg, 10) &&
      header?.hotelNavigation?.navigationItems?.length > 0
    ) {
      setResortMenuOpen(true);
    } else {
      setMenuOpen(true);
    }
  };

  const closeMenus = () => {
    setMenuOpen(false);
    setResortMenuOpen(false);
  };

  return (
    <header
      className={classNames('w-full z-20 ', filledHeader ? 'bg-background-primary relative' : 'absolute top-0')}
      style={
        !filledHeader
          ? { background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.10) 8.11%, rgba(0, 0, 0, 0.00) 100%)' }
          : {}
      }
    >
      <div
        className="container flex justify-between items-center py-[19px] sm:py-6 font-medium font-didot text-white sm:px-10 xl:px-20"
        ref={mainNavRef}
      >
        <div className="w-1/3 flex">
          <button
            onClick={openMenu}
            className={classNames(
              'text-sm not-italic font-medium leading-5 tracking-button-reg uppercase font-montserrat ',
              'cursor-pointer duration-200 flex items-center min-w-32 py-3',
              filledHeader ? 'text-black' : 'text-brand-soft-white',
            )}
            aria-label={t('general.$menu')}
            data-testid="menu-button"
          >
            <Menu className={classNames('sm:mr-2', filledHeader ? 'fill-black' : 'fill-brand-soft-white')} />
            <span className="hidden sm:block">{t('general.$menu')}</span>
          </button>
        </div>
        <div className="w-1/3 flex justify-center">
          {header.logoElement && (
            <Link
              link={header.logoElement.homeLink}
              className="h-6 sm:h-9 lg:h-[43px] aspect-[39/8] flex justify-center"
              aria-label={t('general.$goToHomepage')}
              data-testid="logo"
            >
              <ResponsiveImage
                image={header.logoElement.logo}
                heights={{ xs: 50, md: 50, lg: 50, xl: 50 }}
                className="Gold:hidden Gold:w-0"
                imgProps={{
                  className: classNames('h-full w-full object-contain', filledHeader && 'invert'),
                  height: '48',
                  width: '300',
                  alt: 'logo',
                }}
                dataNotLazy
              />
              <div className="[&_path.montgomeriemaxxroyalLogo\_svg\_\_cls2]:fill-logo-gold hidden" />
              <MontgomeriemaxxroyalLogo
                className={classNames(
                  'h-full w-0 object-contain hidden Gold:block Gold:w-full',
                  filledHeader
                    ? '[&_path]:fill-black'
                    : '[&_path.montgomeriemaxxroyalLogo\\_svg\\_\\_cls2]:fill-logo-gold',
                )}
              />
            </Link>
          )}
        </div>
        <div className="w-1/3 flex justify-end items-center">
          <div className="hidden lg:block w-13 cursor-pointer">
            <LanguageSelect
              metadata={metadata}
              className={classNames(
                filledHeader
                  ? '[&_.popover-title]:text-black [&_.main-button_svg]:fill-black [&_.main-button_svg]:!stroke-black'
                  : '[&_.popover-title]:text-brand-soft-white [&_.main-button_svg]:fill-brand-soft-white [&_.main-button_svg]:stroke-brand-soft-white',
                '[&_.main-button]:px-1.5 [&_.content]:border-0',
              )}
              data-testid="language-select"
            />
          </div>
          <button
            data-testid="contact-button"
            className={classNames(
              filledHeader ? 'primary' : 'secondary',
              header.availabilityBar ? 'hidden lg:flex' : 'flex',
              'btn ml-5 h-[34px] sm:h-[42px] xl:h-13 py-2 sm:py-3 xl:py-3.5 px-5 sm:px-10 xl:px-[46px]',
            )}
            onClick={() => addQueryParam({ contact: true })}
          >
            <span className="text-xs leading-4.5 xl:text-sm xl:leading-5">
              {t('general.$contact').toLocaleUpperCase(router.locale)}
            </span>
          </button>

          {header.availabilityBar && (
            <button
              data-testid="book-button"
              className={classNames(
                filledHeader ? 'primary-dark' : 'secondary-reverse',
                'btn h-[34px] sm:h-[42px] xl:h-13 py-2 sm:py-3 xl:py-3.5 sm:px-10 xl:px-[46px] ml-2 px-5',
                'lang-de:px-3 sm:lang-de:px-5',
                'lang-ru:px-1 sm:lang-ru:px-5 lang-ru:-mr-2 sm:lang-ru:mr-0',
                'lang-tr:px-1 sm:lang-tr:px-5 lang-tr:-mr-2 sm:lang-tr:mr-0',
              )}
              onClick={() => addQueryParam({ book: true })}
            >
              <span
                className="text-xs leading-4.5 xl:text-sm xl:leading-5 whitespace-nowrap
                lang-ru:text-[10px] sm:lang-ru:text-sm"
              >
                {t('general.$book')}
              </span>
            </button>
          )}
        </div>
      </div>
      <Nav
        open={menuOpen}
        onClose={closeMenus}
        navItems={header?.mainNavItems}
        addQueryParam={addQueryParam}
        metadata={metadata}
        availabilityBar={!!header.availabilityBar}
        logoElement={header.logoElement}
        showHomeLink={metadata.pageType === 'resortPage' || header?.hotelNavigation?.navigationItems?.length > 0}
      />

      {header?.hotelNavigation?.navigationItems?.length > 0 && (
        <ResortNav
          open={resortMenuOpen}
          onClose={closeMenus}
          filledHeader={filledHeader}
          navItems={header?.hotelNavigation}
          mainNav={mainNavRef}
          addQueryParam={addQueryParam}
          metadata={metadata}
          availabilityBar={!!header.availabilityBar}
          logoElement={header.logoElement}
        />
      )}
    </header>
  );
};

export default Header;
