import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { createPopper } from '@popperjs/core';
import DropdownArrow from 'assets/dropdown_arrow.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PopoverMenu = forwardRef(function PopoverMenu(
  { title, open, placement, onOpen, onClose, children, className, titleClass, ...props },
  ref,
) {
  const [_open, set_open] = useState();
  const wrapper = useRef();
  const button = useRef();
  const popover = useRef();
  const popper = useRef();

  useImperativeHandle(ref, () => {
    return {
      open() {
        set_open(true);
      },
    };
  });

  useEffect(() => {
    setOpenValue(open);
  }, [open]);

  useEffect(() => {
    if (_open) {
      if (!popper.current) {
        const popperProps = {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
        };

        if (placement) {
          popperProps.placement = placement;
        }
        popper.current = createPopper(button.current, popover.current, popperProps);
      }

      setTimeout(() => {
        window.addEventListener('mousedown', closeIfClickedOutside);
      }, 0);

      onOpen && onOpen();
    } else {
      window.removeEventListener('mousedown', closeIfClickedOutside);
      onClose && onClose();
    }
    return () => {
      window.removeEventListener('mousedown', closeIfClickedOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_open]);

  const closeIfClickedOutside = (e) => {
    let inside = false;
    e.composedPath().forEach((element) => {
      if (wrapper.current === element) {
        inside = true;
      }
    });

    if (!inside) {
      set_open(false);
    }
  };

  const setOpenValue = (newValue) => {
    set_open(newValue);
  };

  return (
    <div ref={wrapper} className={classNames('relative', _open && 'open', className)} {...props}>
      <button
        ref={button}
        type="button"
        className={classNames(
          'main-button flex items-center w-full justify-between px-4 py-3',
          _open && (placement === 'top' ? 'border-t-transparent' : 'border-b-transparent'),
        )}
        onClick={() => setOpenValue(!_open)}
      >
        <span
          className={classNames('popover-title', titleClass)}
          dangerouslySetInnerHTML={{
            __html: title?.replace ? title.replace(/<(p)>/g, '').replace(/<\/(p)>/g, '') : title,
          }}
        />
        <span className="arrow ml-4">
          <DropdownArrow
            role="presentation"
            data-testid="dropdown-arrow"
            className={classNames(
              'fill-white/75 transition',
              _open
                ? placement === 'top'
                  ? 'rotate-180'
                  : '-rotate-180'
                : placement === 'top'
                  ? '-rotate-0'
                  : 'rotate-0',
            )}
          />
        </span>
      </button>
      <div ref={popover} className="w-full z-10">
        <div
          className={classNames(
            'content left-0 w-full bg-white border transform border-grey5 focus:outline-none transition-opacity',
            _open ? 'flex opacity-100 scale-100' : 'hidden opacity-0 scale-0',
          )}
          tabIndex="-1"
        >
          {children}
        </div>
      </div>
    </div>
  );
});

PopoverMenu.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  placement: PropTypes.oneOf(['top', 'bottom']),
};

export default PopoverMenu;
